<template>
  <div class="auth-container">
    <img src="~@/assets/img/no-auth.png" />
    <div>您当前无访问权限！</div>
  </div>
</template>

<script>
export default {
  setup() {
    return {
    };
  },
};
</script>

<style lang='scss'>
.auth-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-third-color);
  > img {
    width: 500px;
  }
}
</style>